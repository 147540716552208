import axios from "axios";

export const buyToken = async (payLoad) => {
  try {
    const { data } = await axios.post(`/wallet/buyTokens`, payLoad);
    return data;
  } catch (err) {
    return err;
  }
};
export const makePayment = async (payLoad) => {
  try {
    const { data } = await axios.post(`/user/makePayment`, payLoad);
    return data;
  } catch (err) {
    return err;
  }
};
export const transferToken = async (payLoad) => {
  try {
    const { data } = await axios.post(`/user/profile/transfer`, payLoad);
    return data;
  } catch (err) {
    return err;
  }
};
export const allTransactions = async (page) => {
  try {
    const { data } = await axios.get(`/user/profile/transactions?page=${page}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getDetailsByEmail = async (query) => {
  try {
    const { data } = await axios.get(`user/profile/getDetailsByEmail?${query}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const addBankAccount = async (payload) => {
  try {
    const { data } = await axios.post(`user/bank-accounts/link`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getBankAccounts = async () => {
  try {
    const { data } = await axios.get(`user/bank-accounts`);
    return data;
  } catch (err) {
    return err;
  }
};
export const deleteBankAccount = async (payload) => {
  try {
    const { data } = await axios.post(`user/bank-accounts/unlink`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const updateBankAccount = async (payload) => {
  try {
    const { data } = await axios.post(`user/bank-accounts/update`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getToken = async (payload) => {
  try {
    const { data } = await axios.post(
      `/schex/token
    `
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const createBuy = async (payload) => {
  try {
    const { data } = await axios.post(
      `/schex/create
    `,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const sellCoinFunc = async (payload) => {
  try {
    const { data } = await axios.post(
      `/schex/send
    `,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
