import React from "react";
import { Route, Switch } from "react-router";
import Home from "../../containers/Home";

import HeaderComponent from "../shared/Header";

const DashboardLayout = () => {
  return (
    <>
      <HeaderComponent />
      <div className="wrapper">
        <div className="content_wrapper">
          <Switch>
            <Route exact path="/token-sale" component={Home} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
