import React, { useState, useContext, useEffect, useCallback } from "react";
import isEmpty from "is-empty";
import Input from "../../components/UI/Input";
import { ToastContainer, toast } from "react-toastify";
import { Button, Switch } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Badge, Col, Form, Nav, Row, Tab, Table } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import moment from "moment";
import utils from "../../components/utils";
import SimpleSnackbar from "../../components/UI/Snakbar";
import AppContext from "../../context";
import PaymentLogo from "../../assets/img/payment.png";
import {
  buyToken,
  allTransactions,
  transferToken,
  getDetailsByEmail,
  makePayment,
  addBankAccount,
  getBankAccounts,
  deleteBankAccount,
  updateBankAccount,
  getToken,
  createBuy,
  sellCoinFunc,
} from "./Service";

import { useUser } from "../../context/UserProvider";
import { userProfile } from "../Profile/ProfileService";
import CustomModal from "../../components/UI/Modal";
import { creditCardType } from "../../utils";
import Input2 from "../../components/UI/Input/Input2";
import Transactions from "./Transactions";
import AccountList from "./AccountList";

/**
 * @author
 * @function Home
 **/

const Home = (props) => {
  const context = useContext(AppContext);
  const { accountAddress, setTokenBalance } = context;
  const { userData, setUserDetailsHandler } = useUser();

  const [buyTrx, setBuyTrx] = useState("");
  const [reviCoin, setReviCoin] = useState("");
  const [usdValue, setUsdValue] = useState("");
  const [buyValue, setBuyValue] = useState("");
  const [sellCoin, setSellCoin] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [allTransactionsList, setAllTransactionsList] = useState([]);
  const [isAddressShow, setIsAddressShow] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isAddressNotFound, seIsAddressNotFound] = useState(false);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [cardDetails, setCardDetails] = useState({});
  const [accountDetails, setAccountDetails] = useState({
    firstName: "",
    lastName: "",
    accountNumber: "",
    routing: "",
    bankName: "",
    ifsc: "",
  });
  const [bankAccountList, setBankAccountList] = useState([]);

  const [transferDetails, setTransferDetails] = useState({
    amount: "",
    address: "",
    email: "",
    name: "",
    userAddress: "",
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");

  const handleCloseConfirmModal = () => setConfirmModalOpen(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);

  const resetBankDetails = () => {
    setAccountDetails({
      firstName: "",
      lastName: "",
      accountNumber: "",
      routing: "",
      bankName: "",
      ifsc: "",
    });
  };

  const handleToggleAddressType = () => {
    setTransferDetails((prevState) => ({
      ...prevState,
      name: "",
      addressViaAPI: "",
      email: "",
      address: "",
    }));
    seIsAddressNotFound(false);
    setIsEmailInvalid(false);
    setIsAddressShow((prevState) => !prevState);
  };

  const handleChangeTransfer = (e) => {
    const { name, value } = e.target;
    setTransferDetails((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleChangeAccount = (e) => {
    const { name, value } = e.target;
    setAccountDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setBuyTrx(value);
    setReviCoin(value);
  };
  const handleChangeSell = (e) => {
    const { value } = e.target;
    setSellCoin(value);
    setUsdValue(value);
  };

  const handleCardChange = (e) => {
    const { name, value } = e.target;
    setCardDetails((prev) => ({ ...prev, [name]: value }));
    if (name === "number") {
      const type = creditCardType(value);
      console.log(type, "cardType");
      setCardDetails((prev) => ({ ...prev, cardType: type }));
    }
  };

  const handleSecurityChange = (e) => {};

  const onSubmitHandler = async () => {
    console.log("hitted");

    try {
      // eslint-disable-next-line no-unused-vars
      const buy = await utils.tspReviCoinContract
        .buyTokens()
        .send({ callValue: buyTrx * 10 ** 6 });

      handleClick();

      setMessage(`${buyTrx}  Revicoin Successfully Bought`);
      tspsweepToken();
      console.log(buyTrx, "buyvalue");
    } catch (error) {
      console.log(error, "err");
    }
  };
  const tspsweepToken = useCallback(async () => {
    console.log(accountAddress, "accountAddress");
    if (accountAddress) {
      try {
        setTimeout(async () => {
          const sweep = await utils.tspReviCoinContract
            .balanceOf(accountAddress)
            .call();
          const sweeptoken = +sweep;
          setTokenBalance(sweeptoken);

          console.log(sweeptoken, "sweeptoken ");
        }, 2000);
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, [accountAddress, setTokenBalance]);

  useEffect(() => {
    tspsweepToken();
  }, [tspsweepToken]);

  const userProfileHandler = async () => {
    const res = await userProfile();

    setUserDetailsHandler(res.data);
    console.log(res, "resData");
  };

  // const buyTokenHandler = async () => {
  //   const { holder, number, expiryMonth, expiryYear, cvv, cardType } =
  //     cardDetails;
  //   const payload = {
  //     amount: buyTrx,
  //     currency: "INR",
  //     paymentBrand: cardType,
  //     paymentType: "DB",
  //     card: {
  //       number,
  //       holder,
  //       expiryMonth,
  //       expiryYear,
  //       cvv,
  //     },
  //   };
  //   const res = await buyToken(payload);
  //   console.log(res);
  //   if (res.status) {
  //     getAllTransactions();
  //     handleClick();
  //     userProfileHandler();
  //     setBuyTrx("");
  //     setReviCoin("");

  //     setMessage(`${buyTrx}  Revicoin Successfully Bought`);
  //   }
  // };

  const getAllTransactions = async (pageNumber = 1) => {
    const res = await allTransactions(pageNumber);

    if (res.status) {
      for (const data of res.data) {
        for (const innerData of data.metadata) {
          setTotalPagesCount(innerData.total);
        }
      }
      const resData = res.data.map((p) => p.data.map((k) => k));
      console.log(resData, "resData");
      setAllTransactionsList(...resData);
    }

    console.log(res, "resDataT");
  };

  useEffect(() => {
    getAllTransactions();
  }, []);

  const transferTokenHandler = async () => {
    const { amount, address, addressViaAPI } = transferDetails;
    let addressTemp = address;
    if (!isAddressShow) {
      addressTemp = addressViaAPI;
    }
    const payload = {
      address: addressTemp,
      amount,
    };
    const res = await transferToken(payload);
    if (res.status) {
      getAllTransactions();
      setTransferDetails({
        address: "",
        amount: "",
        email: "",
        name: "",
      });
      handleClick();
      handleCloseConfirmModal();
      setMessage(`Amount transfer successfully!`);
    }
    console.log(res, "resTransfer");
  };

  const getDetailsByEmailHandler = async (e, type) => {
    const { value } = e.target;
    let tempQuery;
    if (value) {
      if (type === "address") {
        tempQuery = `address=${value}`;
      } else if (type === "email") {
        tempQuery = `email=${value}`;
      }
      const res = await getDetailsByEmail(tempQuery);
      if (res.status) {
        seIsAddressNotFound(false);
        setIsEmailInvalid(false);
        handleCloseConfirmModal();
        setTransferDetails((prevState) => ({
          ...prevState,
          addressViaAPI: res.data.address,
          name: res.data.name,
          email: res.data.email,
          userAddress: res.data.address,
        }));
      } else {
        setTransferDetails((prevState) => ({
          ...prevState,
          name: "",
          addressViaAPI: "",
        }));
        setIsEmailInvalid(true);
        if (type === "address") {
          seIsAddressNotFound(true);
        }
      }
    }
  };

  const handleCheckChange = (e) => {
    if (e.target.checked) {
      setTransferDetails((prevState) => ({
        ...prevState,

        addressViaAPI: "checked",
      }));
    } else {
      setTransferDetails((prevState) => ({
        ...prevState,

        addressViaAPI: "",
      }));
    }
  };

  const isTransferButtonDisabled = () => {
    let isValid = true;
    if (
      !isEmpty(transferDetails.amount) &&
      !isEmailInvalid &&
      !isEmpty(transferDetails.addressViaAPI)
    ) {
      isValid = false;
    }

    console.log(
      !isEmpty(transferDetails.amount),
      !isEmailInvalid,
      !isEmpty(transferDetails.addressViaAPI),
      "checkValid"
    );

    return isValid;
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getAllTransactions(value);
  };

  console.log(userData, "userData");

  const makePaymentHandler = async () => {
    const { holder, number, expiryMonth, expiryYear, cvv } = cardDetails;
    const payload = {
      holder,
      number,
      expiryMonth,
      expiryYear,
      cvv,
    };
    const res = await makePayment(payload);
    console.log(res, "resPayment");
  };

  const addBankAccountHandler = async () => {
    const { accountNumber, routing, bankName, ifsc, firstName, lastName } =
      accountDetails;
    const payload = {
      first_name: firstName,
      last_name: lastName,
      account_number: accountNumber,
      routing,
    };
    if (bankName) {
      payload.bank_name = bankName;
    }
    if (ifsc) {
      payload.ifsc = ifsc;
    }
    const res = await addBankAccount(payload);
    console.log(res, "resData");
    if (res.status) {
      setBankAccountList([res.data]);
      toast("Bank account added successfully");
      resetBankDetails();
      getBankAccountsHandler();
      getTokenHandler();
      window.location.href = "/";
    }
  };

  const getBankAccountsHandler = async () => {
    const res = await getBankAccounts();
    console.log(res, "resBank");
    if (res.status) {
      setBankAccountList(res.data);
    }
  };

  useEffect(() => {
    getBankAccountsHandler();
  }, []);

  const deleteBankAccountHandler = async (id) => {
    const payload = {
      account_id: id,
    };
    const res = await deleteBankAccount(payload);
    if (res.status) {
      toast("Bank account deleted successfully");
      getBankAccountsHandler();
    }
  };

  const onEditHandler = (data) => {
    setIsEditMode(true);
    setAccountDetails({
      firstName: data.first_name,
      lastName: data.last_name,
      accountNumber: data.account_number,
      routing: data.routing,
      bankName: data.bank_name,
      ifsc: data.ifsc,
      id: data._id,
    });
  };

  const onUpdateAccountHandler = async () => {
    const { accountNumber, routing, bankName, ifsc, id } = accountDetails;
    const payload = {
      account_id: id,
      account_number: accountNumber,
      routing,
      bank_number: bankName,
      ifsc,
    };
    const res = await updateBankAccount(payload);
    if (res.status) {
      toast("Bank account updated successfully");
      resetBankDetails();
      getBankAccountsHandler();
    }
  };

  const getTokenHandler = async () => {
    const res = await getToken();
    console.log(res, "resToken");
    if (res.status) {
      setToken(res.data.token);
    }
  };

  useEffect(() => {
    if (bankAccountList.length > 0) {
      getTokenHandler();
    }
  }, [bankAccountList.length]);

  const buyTokenHandler = async () => {
    if (bankAccountList.length === 0) {
      return toast("Please add bank account first");
    }
    const payload = {
      token: token,
      amount: buyTrx,
      phone: "9910188805",
    };

    const res = await createBuy(payload);
    if (res?.response) {
      return toast.error(res?.response.data.message);
    }
    if (res.status) {
      toast("Transaction Initiated Successfully");
    } else {
      toast.error(res.message);
    }
  };

  const sellCoinHandler = async () => {
    const payload = { amount: sellCoin };
    const res = await sellCoinFunc(payload);
    console.log(res?.response);
    if (res?.response) {
      return toast.error(res?.response.data.message);
    }
    if (res.status) {
    } else {
      toast.error(res.message);
    }
  };

  return (
    <>
      <div className="login_wrapper ">
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="first">Buy</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Sell</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Transfer</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">Add Bank</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="token_sale_wrap">
                  <div className="form_wrap">
                    <div className="head">
                      <h1>
                        Buy
                        <br /> Revicoin
                      </h1>
                      <img src={PaymentLogo} alt="" />
                    </div>

                    <form>
                      <div className="input_box login">
                        <Row>
                          <Col md={6}>
                            <Input
                              label="Amount you pay"
                              placeholder=""
                              name="trx"
                              type="number"
                              value={buyTrx}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={6}>
                            <Input
                              label="Revicoin you get"
                              placeholder=""
                              name="revicoin"
                              type="text"
                              value={reviCoin}
                              readOnly
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group"></div>

                      {/* <div className="form-roup mt-3">
                        <Input
                          fullWidth
                          label="Enter name on card"
                          placeholder=""
                          name="holder"
                          type="text"
                          value={cardDetails?.holder}
                          onChange={handleCardChange}
                        />
                      </div>
                      <div className="form-roup mt-3">
                        <Input2
                          fullWidth
                          label="Enter card number"
                          placeholder=""
                          name="number"
                          type="text"
                          value={cardDetails?.number}
                          onChange={handleCardChange}
                          cardType={cardDetails?.cardType}
                        />
                      </div>
                      <div className="form-roup mt-3">
                        <Row>
                          <Col md={4}>
                            <Input
                              label="Expiry month"
                              placeholder=""
                              name="expiryMonth"
                              type="text"
                              value={cardDetails?.expiryMonth}
                              onChange={handleCardChange}
                            />
                          </Col>
                          <Col md={4}>
                            <Input
                              label="Expiry year"
                              placeholder=""
                              name="expiryYear"
                              type="text"
                              value={cardDetails?.expiryYear}
                              onChange={handleCardChange}
                            />
                          </Col>
                          <Col md={4}>
                            <Input
                              label="CVV"
                              placeholder=""
                              name="cvv"
                              type="text"
                              value={cardDetails?.cvv}
                              onChange={handleCardChange}
                            />
                          </Col>
                        </Row>
                      </div> */}

                      <div className="form-group mt-3">
                        <Input
                          fullWidth
                          label="Address"
                          placeholder=""
                          name="address"
                          type="text"
                          value={userData?.address}
                          readOnly
                          className="disabled"
                        />
                        {/* <p className="text-right text-dark mt-1">
                              Generate address for me
                            </p> */}
                      </div>

                      <div className="button_wrap">
                        <div className="d-flex justify-content-between">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={buyTokenHandler}
                            disabled={buyTrx ? false : true}
                          >
                            Buy Revicoin
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <div className="security_wrap">
                    <div className="head">
                     
                    </div>
                    <div className="body">
                      <div className="switch_wrap">
                        <h6>
                          Identity
                          <p>Disable</p>
                        </h6>
                        <Switch
                          onChange={handleSecurityChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                      <div className="switch_wrap">
                        <h6>
                          Phone
                          <p>Enable</p>
                        </h6>
                        <Switch
                          onChange={handleSecurityChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                <Transactions
                  allTransactionsList={allTransactionsList}
                  userData={userData}
                  totalPagesCount={totalPagesCount}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="token_sale_wrap">
                  <div className="form_wrap">
                    <div className="head">
                      <h1>
                        Sell
                        <br /> Revicoin
                      </h1>
                      <img src={PaymentLogo} alt="" />
                    </div>

                    <form>
                      <div className="input_box login">
                        <Row>
                          <Col>
                            <Input
                              label="Token you pay"
                              placeholder=""
                              name="trx"
                              type="number"
                              value={sellCoin}
                              onChange={handleChangeSell}
                            />
                          </Col>
                          <Col>
                            <Input
                              label="USD you get"
                              placeholder=""
                              name="revicoin"
                              type="text"
                              value={usdValue}
                              readOnly
                            />
                          </Col>
                        </Row>
                      </div>

                      <div className="button_wrap mt-4">
                        <div className="d-flex justify-content-between">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={sellCoinHandler}
                            disabled={isEmpty(sellCoin)}
                          >
                            Sell Revicoin
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <div className="security_wrap">
                    <div className="head">
                    
                    </div>
                    <div className="body">
                      <div className="switch_wrap">
                        <h6>
                          Identity
                          <p>Disable</p>
                        </h6>
                        <Switch
                          onChange={handleSecurityChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                      <div className="switch_wrap">
                        <h6>
                          Phone
                          <p>Enable</p>
                        </h6>
                        <Switch
                          onChange={handleSecurityChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                <Transactions
                  allTransactionsList={allTransactionsList}
                  userData={userData}
                  totalPagesCount={totalPagesCount}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="token_sale_wrap">
                  <div className="form_wrap">
                    <div className="head">
                      <h1>Transfer Revicoin</h1>
                    </div>

                    <form>
                      <div className="input_box login">
                        <Form.Group>
                          <Input
                            label="Amount"
                            placeholder="Enter RVT"
                            name="amount"
                            type="number"
                            value={transferDetails.amount}
                            onChange={handleChangeTransfer}
                            className="w-100"
                          />
                        </Form.Group>
                        <Form.Group className="email_group">
                          {isAddressShow ? (
                            <Input
                              label="Reciever address"
                              placeholder="Enter Address"
                              name="address"
                              type="text"
                              className="w-100"
                              value={transferDetails.address}
                              onChange={handleChangeTransfer}
                              onBlur={(e) =>
                                getDetailsByEmailHandler(e, "address")
                              }
                            />
                          ) : (
                            <Input
                              label="Email"
                              placeholder="Email registred email address"
                              name="email"
                              type="email"
                              className="w-100"
                              value={transferDetails.email}
                              onChange={handleChangeTransfer}
                              onBlur={(e) =>
                                getDetailsByEmailHandler(e, "email")
                              }
                            />
                          )}
                          <div className="info_wrap_bottom">
                            {isEmailInvalid && (
                              <p className="text-danger">User not found! </p>
                            )}

                            {transferDetails.name && (
                              <p className="text-dark">
                                {transferDetails.name} <CheckCircleIcon />
                              </p>
                            )}
                            <p
                              className="change_type_p"
                              onClick={handleToggleAddressType}
                            >
                              Send on{" "}
                              {isAddressShow ? "email" : "wallet address"}{" "}
                              instead
                            </p>
                          </div>
                        </Form.Group>
                      </div>

                      <div className="button_wrap mt-4">
                        {isAddressNotFound && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleCheckChange}
                                color="primary"
                              />
                            }
                            label="Send tokens on this address anyway."
                          />
                        )}

                        <div className="d-flex justify-content-between">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleOpenConfirmModal}
                            disabled={isTransferButtonDisabled()}
                          >
                            Transfer
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <div className="security_wrap">
                    <div className="head">
                   
                    </div>
                    <div className="body">
                      <div className="switch_wrap">
                        <h6>
                          Identity
                          <p>Disable</p>
                        </h6>
                        <Switch
                          onChange={handleSecurityChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                      <div className="switch_wrap">
                        <h6>
                          Phone
                          <p>Enable</p>
                        </h6>
                        <Switch
                          onChange={handleSecurityChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                <Transactions
                  allTransactionsList={allTransactionsList}
                  userData={userData}
                  totalPagesCount={totalPagesCount}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <div className="token_sale_wrap">
                  <div className="form_wrap">
                    <div className="head mb-4">
                      <h1>Add Bank Account</h1>
                    </div>

                    <form>
                      <Form.Group>
                        <Input
                          label="First Name"
                          placeholder="Enter first name"
                          name="firstName"
                          type="text"
                          value={accountDetails.firstName}
                          onChange={handleChangeAccount}
                          className="w-100"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Input
                          label="Last Name"
                          placeholder="Enter last name"
                          name="lastName"
                          type="text"
                          value={accountDetails.lastName}
                          onChange={handleChangeAccount}
                          className="w-100"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Input
                          label="Account Number"
                          placeholder="Enter account number"
                          name="accountNumber"
                          type="number"
                          value={accountDetails.accountNumber}
                          onChange={handleChangeAccount}
                          className="w-100"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Input
                          label="Routing"
                          placeholder="Enter routing"
                          name="routing"
                          type="number"
                          value={accountDetails.routing}
                          onChange={handleChangeAccount}
                          className="w-100"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Input
                          label="Bank Name (optional)"
                          placeholder="Enter bank name"
                          name="bankName"
                          type="text"
                          value={accountDetails.bankName}
                          onChange={handleChangeAccount}
                          className="w-100"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Input
                          label="IFSC (optional)"
                          placeholder="Enter ifsc code"
                          name="ifsc"
                          type="text"
                          value={accountDetails.ifsc}
                          onChange={handleChangeAccount}
                          className="w-100"
                        />
                      </Form.Group>

                      <div className="button_wrap mt-4">
                        <div className="d-flex justify-content-between">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={
                              isEditMode
                                ? onUpdateAccountHandler
                                : addBankAccountHandler
                            }
                            disabled={
                              isEmpty(accountDetails.firstName) ||
                              isEmpty(accountDetails.lastName) ||
                              isEmpty(accountDetails.accountNumber) ||
                              isEmpty(accountDetails.routing)
                            }
                          >
                            {isEditMode ? "Update" : "Add"}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <AccountList
                  list={bankAccountList}
                  onDelete={deleteBankAccountHandler}
                  onEdit={onEditHandler}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <SimpleSnackbar open={open} message={message} handleClose={handleClose} />
      <CustomModal
        show={confirmModalOpen}
        handleClose={handleCloseConfirmModal}
        title="Confirm"
      >
        <div className="confirm_wrap">
          <h5>Are you sure you want to tranfer {transferDetails.amount}RVT?</h5>
          <div className="body_wrap">
            {transferDetails.name && (
              <p>
                Name: <strong>{transferDetails.name}</strong>
              </p>
            )}
            {transferDetails.email && (
              <p>
                Email: <strong>{transferDetails.email}</strong>
              </p>
            )}

            <p>
              Address: <strong>{transferDetails.userAddress}</strong>
            </p>
          </div>
          <Button
            variant="outlined"
            color="secondary"
            className="mr-3"
            onClick={handleCloseConfirmModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={transferTokenHandler}
          >
            Transfer
          </Button>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default Home;
