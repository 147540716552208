import React, { createContext, useContext } from "react";

import { useState } from "react";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export default function UserProvider({ children }) {
  const [userData, setUserData] = useState({});

  const setUserDetailsHandler = (data) => {
    console.log(data, "checkData");
    setUserData(data);
  };

  const values = {
    userData,
    setUserDetailsHandler,
  };
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}
