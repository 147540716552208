import React, { useContext, useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/img/Logo.png";
import Alen from "../../assets/img/alen.png";
import { NavLink, useHistory } from "react-router-dom";
import AppContext from "../../context";
import { userProfile } from "../../containers/Profile/ProfileService";
import { useUser } from "../../context/UserProvider";

/**
 * @author
 * @function HeaderComponent
 **/

const HeaderComponent = (props) => {
  const context = useContext(AppContext);
  const { availableBalance } = context;
  const { setUserDetailsHandler, userData } = useUser();

  let history = useHistory();

  const logoutHandler = () => {
    localStorage.clear();
    history.push("/login");
  };

  const userProfileHandler = async () => {
    const res = await userProfile();

    setUserDetailsHandler(res.data);
    console.log(res, "resData");
  };

  useEffect(() => {
    userProfileHandler();
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <img src={Logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            {/* <NavLink
              className="nav-link"
              activeClassName="active"
              to="/profile"
            >
              <i className="icon chat"></i>
            </NavLink>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/profile"
            >
              <i className="icon notification"></i>
            </NavLink>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/profile"
            >
              <i className="icon wallet-outline"></i>
            </NavLink> */}
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/profile"
            >
              <i className="icon wallet-outline"></i>
              <strong
                style={{ color: "#fff", fontSize: 18, marginTop: 7 }}
                className="ml-2"
              >
                {" "}
                {userData?.balance}
              </strong>
            </NavLink>

            <a
              className="nav-link"
              target="_blank"
              href={`https://www.revitstream.com/profile/${userData._id}`}
              rel="noopener noreferrer"
            >
              {userData?.profilePic ? (
                <img className="dp" alt="dp" src={userData?.profilePic} />
              ) : (
                <span className="dp_name">{userData?.name?.charAt(0)}</span>
              )}
            </a>
            {/* <NavLink className="nav-link dropdown" activeClassName="active">
              <i className="icon three-dot"></i>
            </NavLink> */}
            <NavDropdown
              title={<i className="icon three-dot"></i>}
              id="basic-nav-dropdown"
            >
              <a
                className="dropdown-item"
                target="_blank"
                href={`https://www.revitstream.com/profile/${userData._id}`}
                rel="noopener noreferrer"
              >
                <i className="icon user-dark"></i> My Profile
              </a>
              {/* <NavDropdown.Item href="#action/3.2">
                <i className="icon setting"></i> Settings
              </NavDropdown.Item> */}
              <NavDropdown.Item
                href="javascript:void(0)"
                onClick={logoutHandler}
              >
                <i className="icon logout"></i> Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default HeaderComponent;
