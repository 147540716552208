import moment from "moment";
import React from "react";
import { Badge, Button, Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";

const AccountList = ({ list, onDelete, onEdit }) => {
  return (
    <>
      <div className="transactions_wrap ">
        <div className="head">
          <h6>Bank Accounts</h6>
          {/* <span>View All</span> */}
        </div>

        {list.length > 0 ? (
          <>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Account No.</th>
                  <th>Routing</th>
                </tr>
              </thead>
              <tbody>
                {list.map((bank) => {
                  return (
                    <tr>
                      <td>
                        {moment(bank.created_at).format("MMM Do YYYY hh:mm A")}
                      </td>
                      <td>{bank.account_number}</td>
                      <td>{bank.routing}</td>
                      <td>
                        <Button onClick={() => onEdit(bank)}>Edit</Button>
                        <Button
                          variant="secondary"
                          className="ml-2"
                          onClick={() => onDelete(bank._id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <h5 className="no_trasaction_text">No bank account found</h5>
        )}
        {/* <div className="pagination_wrap">
          <Pagination
            count={totalPagesCount}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </div> */}
      </div>
    </>
  );
};

export default AccountList;
