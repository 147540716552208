import React from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/app.scss";
import UserProvider from "./context/UserProvider";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <CssBaseline />
        <UserProvider>
          <Routes />
        </UserProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
