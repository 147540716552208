import React from "react";
import { Redirect, Switch } from "react-router-dom";

import AuthRoutes from "../routes/AuthRoutes";
import UnAuthRoutes from "../routes/UnAuthRoutes";

import ProfileLayout from "../components/Layout";
import SignupScreen from "../containers/Auth/Signup";
import ResetPasswordScreen from "../containers/Auth/ResetPassword";
import CreateNewPasswordScreen from "../containers/Auth/CreateNewPassword";
import SigninScreen from "../containers/Auth/Signin";

const Routes = () => {
  return (
    <Switch>
      <AuthRoutes path={"/token-sale"} component={ProfileLayout} />
      <UnAuthRoutes path={"/login"} component={SigninScreen} />
      <UnAuthRoutes path={"/signup"} component={SignupScreen} />
      <UnAuthRoutes path={"/reset-password"} component={ResetPasswordScreen} />
      <UnAuthRoutes
        path={"/create-new-password"}
        component={CreateNewPasswordScreen}
      />
      <Redirect to="/login" />
    </Switch>
  );
};

export default Routes;
