import React from 'react'
import { Modal } from 'react-bootstrap'

/**
 * @author
 * @function CustomModal
 **/

const CustomModal = (props) => {
  const { show, handleClose, title, children, size, className } = props
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size={size}
        centered
        className={className}
      >
        <Modal.Header>
          <Modal.Title>
            {title}
            <span className="close" onClick={handleClose}>
              <i className="icon cancel"></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  )
}

export default CustomModal
