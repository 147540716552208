import moment from "moment";
import React from "react";
import { Badge, Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";

const Transactions = ({
  allTransactionsList,
  userData,
  totalPagesCount,
  page,
  handlePageChange,
}) => {
  return (
    <>
      <div className="transactions_wrap ">
        <div className="head">
          <h6>Transactions</h6>
          {/* <span>View All</span> */}
        </div>

        {allTransactionsList.length > 0 ? (
          <>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Transaction ID</th>
                  <th>Transaction Status</th>
                </tr>
              </thead>
              <tbody>
                {allTransactionsList.map((transaction) => {
                  return (
                    <tr>
                      <td>
                        {moment(transaction.created_at).format(
                          "MMM Do YYYY hh:mm A"
                        )}
                      </td>
                      <td>{transaction.amount}</td>
                      <td className="text-center">
                        {transaction.type === "USER_TRANSFER" && (
                          <>
                            <Badge
                              variant={`${
                                transaction.fromUserObj._id === userData._id
                                  ? "danger"
                                  : "success"
                              }`}
                            >
                              {transaction.fromUserObj._id === userData._id
                                ? "Send"
                                : "Recieved"}
                            </Badge>
                            {transaction.fromUserObj._id !== userData._id && (
                              <>
                                <p className="mb-0 text-center mt-1">
                                  From
                                  <a
                                    className="ml-1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://revitstream.com/profile/${transaction.fromUserObj._id}`}
                                  >
                                    {transaction.fromUserObj.name}
                                  </a>
                                </p>
                              </>
                            )}
                            {transaction.toUserObj._id !== userData._id && (
                              <>
                                <p className="mb-0 text-center mt-1">
                                  To
                                  <a
                                    className="ml-1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://revitstream.com/profile/${transaction.toUserObj._id}`}
                                  >
                                    {transaction.toUserObj.name}
                                  </a>
                                </p>
                              </>
                            )}

                            {/* <p className="mb-0 text-center">to</p>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://revitstream.com/profile/${transaction.toUserObj._id}`}
                                >
                                  {transaction.toUserObj.name}
                                </a> */}
                          </>
                        )}
                        {transaction.type === "BUY_TOKEN" && (
                          <Badge variant={"success"}>Token Buy</Badge>
                        )}
                        {transaction.type === "TOKEN_CREDIT" && (
                          <Badge variant={"success"}>Poker Chips</Badge>
                        )}
                      </td>

                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://tronscan.org/#/transaction/${transaction.resp}`}
                        >
                          {transaction.resp}
                        </a>
                      </td>
                      <td>
                        <Badge
                          variant={`${
                            transaction.status === "PENDING"
                              ? "warning"
                              : transaction.status === "ACCEPTED"
                              ? "success"
                              : transaction.status === "FAILED"
                              ? "danger"
                              : ""
                          }`}
                        >
                          {transaction.status}
                        </Badge>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <h5 className="no_trasaction_text">No transactions</h5>
        )}
        <div className="pagination_wrap">
          <Pagination
            count={totalPagesCount}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Transactions;
