import React, { Component } from 'react'

const AppContext = React.createContext()

class AppProvider extends Component {
  // Context state
  state = {
    test: 'this is just for testing',
    accountAddress: '',
    availableBalance: '',
  }

  setAccountAddressContext = (address) => {
    this.setState({ accountAddress: address })
  }
  setTokenBalance = (token) => {
    this.setState({ availableBalance: token })
  }

  render() {
    const { children } = this.props
    const { state, setAccountAddressContext, setTokenBalance } = this

    return (
      <AppContext.Provider
        value={{
          ...state,
          setAccountAddressContext,
          setTokenBalance,
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}

export default AppContext

export { AppProvider }
