const tspReviCoinContractAddress = '4100bb3315e44da69fefd076afdcaf6fc756ffc605'

const utils = {
  tronWeb: false,
  contract: false,
  tspReviCoinContract: false,
  async setTronWeb(tronWeb) {
    // console.log("^^^^^^^^^^^^^^^",tronWeb)
    try {
      console.log(tspReviCoinContractAddress, 'tspReviCoinContractAddress')
      this.tronWeb = tronWeb
      this.tspReviCoinContract = await tronWeb
        .contract()
        .at(tspReviCoinContractAddress)
    } catch (error) {
      console.log(error, 'error')
    }
  },
}

export default utils
