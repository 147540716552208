import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../assets/img/Logo.png";
import LoginImg from "../../../assets/img/login_img.png";
import Input from "../../../components/UI/Input";
import AuthFooter from "../../../components/shared/AuthFooter";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { userSignup } from "../AuthService";
import SimpleSnackbar from "../../../components/UI/Snakbar";

/**
 * @author
 * @function SignupScreen
 **/

const SignupScreen = (props) => {
  let history = useHistory();
  const [checked, setChecked] = useState(true);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setUserDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const userSignupHandler = async () => {
    const { fullName, email, password } = userDetails;
    const payload = {
      email,
      password,
      confirmPassword: password,
      name: fullName,
      country: "india",
    };
    const res = await userSignup(payload);

    if (res.status) {
      setOpen(true);
      setMessage("Signup successfull");
      setTimeout(() => {
        history.push("/login");
      }, 1000);
    } else {
      setError(res?.response.data.message);
    }
    // const resData = res
    // if (resData.data.status) {
    // } else {
    //   setError(resData.data.message)
    // }
  };

  const gotToSigninHandler = () => {
    history.push("/login");
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="head">
          <Link to="/">
            <img src={Logo} className="logo" alt="logo" />
          </Link>
        </div>
        <div className="middle_content">
          <Container>
            <Row>
              <Col md={7}>
                <div className="form_wrap">
                  <h1>
                    Welcome to
                    <br /> Online Streaming System
                  </h1>
                  <p>
                    {/* Come join the LT Community! */}
                    Let’s set up your account.
                  </p>
                  <div className="input_box signup">
                    <Input
                      label="Full Name"
                      placeholder="enter your name"
                      icon="user"
                      name="fullName"
                      onChange={changeHandler}
                    />
                    <Input
                      label="Email ID"
                      placeholder="enter your email"
                      icon="email"
                      name="email"
                      onChange={changeHandler}
                      type="email"
                    />
                    {/* <Input
                      label="Password"
                      placeholder="enter your password"
                      icon="lock"
                      name="password"
                      onChange={changeHandler}
                      type="password"
                    /> */}
                    <div className="input_wrap">
                      <TextField
                        label="Password"
                        placeholder="enter your password"
                        name="password"
                        type={values.showPassword ? "text" : "password"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={changeHandler}
                        variant="filled"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              {/* <i className={`icon ${icon}`}></i> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="remomber_forgot_wrap signup">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={
                        <span>
                          You are agreeing to the
                          <>
                            <Link to="/terms">Terms of Services</Link>
                          </>
                          and
                          <>
                            <Link to="/privacy">Privacy Policy</Link>
                          </>
                        </span>
                      }
                    />
                  </div>

                  <div className="button_wrap">
                    {error && <p className="error ">{error}</p>}
                    <div className="d-flex justify-content-between">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={userSignupHandler}
                        disabled={!checked}
                      >
                        Sign Up
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={gotToSigninHandler}
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={5}>
                <div className="login_right">
                  <div className="image_wrap">
                    <img src={LoginImg} alt="" />
                    <div className="circle"></div>
                    <div className="circle_gray"></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer_content"></div>
        <AuthFooter />
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default SignupScreen;
