import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../assets/img/Logo.png";
import LoginImg from "../../../assets/img/login_img.png";
import Input from "../../../components/UI/Input";
import AuthFooter from "../../../components/shared/AuthFooter";
import { forgotPassword } from "../AuthService";
import SimpleSnackbar from "../../../components/UI/Snakbar";

/**
 * @author
 * @function ResetPasswordScreen
 **/

const ResetPasswordScreen = (props) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const forgotPassowrdHandler = async () => {
    const payload = {
      email,
    };
    const res = await forgotPassword(payload);
    if (res && res.status) {
      setOpen(true);
      setMessage(res.message);
    }
  };
  return (
    <>
      <div className="login_wrapper">
        <div className="head">
          <img src={Logo} className="logo" alt="logo" />
        </div>
        <div className="middle_content">
          <Container>
            <Row>
              <Col md={7}>
                <div className="form_wrap">
                  <h1>
                    Reset
                    <br /> Password
                  </h1>
                  <p>
                    Enter the email associated with your account <br />
                    and we'll send an email with instructions
                    <br /> to reset your password.
                  </p>
                  <div className="input_box reset_password">
                    <Input
                      label="Email ID/Mobile Number"
                      placeholder="enter your email or mobile number"
                      icon="email"
                      type="text"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="button_wrap">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={forgotPassowrdHandler}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md={5}>
                <div className="login_right">
                  <div className="image_wrap">
                    <img src={LoginImg} alt="" />
                    <div className="circle"></div>
                    <div className="circle_gray"></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer_content"></div>
        <AuthFooter />
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={message} />
    </>
  );
};

export default ResetPasswordScreen;
