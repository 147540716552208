import axios from 'axios'

export const userProfile = async () => {
  try {
    const { data } = await axios.get(`auth/profile`)
    return data
  } catch (err) {
    return err
  }
}
export const userProfileUpdate = async (payload) => {
  try {
    const { data } = await axios.post(`user/profile`, payload)
    return data
  } catch (err) {
    return err
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { userProfile, userProfileUpdate }
